
const PREF = {
  DEV: {
    child: "tb_child_modules_dev",
    product: "tb_product_preference_dev",
    module: "tb_module_preference_dev",
  },
  SANDBOX: {
    child: "tb_child_modules",
    product: "tb_product_preference",
    module: "tb_module_preference",
  },
  SANDBOX_PRICE: {
    child: "tb_child_modules",
    product: "tb_product_preference_dev_ui",
    module: "tb_module_preference",
  },
  DEMO: {
    child: "tb_child_modules_demo",
    product: "tb_product_preference_demo",
    module: "tb_module_preference_demo",
  },
};
export function getPreference(url) {
  if (url.includes("sandbox.pricesmart")) return PREF.SANDBOX_PRICE;
  const buildRef = `${process.env.REACT_APP_MENU_PREF}`
  switch (buildRef) {
    case "local":
      return PREF.DEV;
    case "dev":
      return PREF.DEV;
    case "sandbox":
      return PREF.SANDBOX;
    case "demo":
      return PREF.DEMO;
    default:
      return PREF.DEV;
  }
  // if (url.includes("local")) return PREF.DEV;
  // if (url.includes("dev.products")) return PREF.DEV;
  // if (url.includes("sandbox.pricesmart")) return PREF.SANDBOX_PRICE;
  // if (url.includes("sandbox")) return PREF.SANDBOX;
}