import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import {
  getDropdownValues,
  setHelpDesk,
} from "../../actions/tenantConfigActions";
import {
  setNotifications,
  setNotificationDND,
  setWebSocketClient,
} from "../../actions/notificationActions";
import Notification from "../../pages/notifications/components/notifications-model";
import { firebaseobj } from "../../commonComponents/auth/firebase";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { WEBSOCKET_URL } from "../../config/api";
import { Header as HeaderRenderer } from "impact-ui-v3";

import { setActiveUserApp } from "actions/sideBarActions";
import _ from "lodash";
import CustomLogo from "./customLogo";

function Header(props) {
  const { headerDetails } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [unreadCount, setUnreadCount] = useState(0);
  const [dnd, setDnd] = useState(false);
  const [notifications, setNotificationsData] = useState({});
  const [open, setOpen] = useState(false);
  let userName = localStorage.getItem("name")
    ? localStorage.getItem("name").split("@")[0]
    : "User";

  userName = userName
    .split(".")
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
    .join(" ");

  const connectWebsocket = async () => {
    const user = firebaseobj.auth().currentUser;
    let token = await user.getIdToken();
    const client = null;

    new W3CWebSocket(`${WEBSOCKET_URL}&token=${token}`);

    client.onopen = () => {
      props.setWebSocketClient(client);
    };

    /**
     * @desc Callback function to handle data when recieved through WebScoket
     * @param {Object} message
     */
    client.onmessage = (message) => {
      const dataFromServer = JSON.parse(message.data);
      if (dataFromServer.data) {
        if (!dataFromServer.page) {
          props.setNotificationDND(dataFromServer.data.dnd);
        } else {
          // Update the latest recieved notifications
          let updateNotifications = {
            notifications: dataFromServer.data.notifications,
            unread_count: dataFromServer.data.unread_count,
            dnd: dataFromServer.data.dnd,
            total: dataFromServer.total,
          };
          props.setNotifications(updateNotifications);
          setNotificationsData(updateNotifications.notifications);
        }
      }

      if (dataFromServer.unread_count || dataFromServer.unread_count === 0) {
        setUnreadCount(dataFromServer.unread_count);
      }
    };
    client.onclose = () => {
      // convert date to a string in UTC timezone format:
      connectWebsocket();
    };
    //setWsClient(client);
  };

  const createNotification = (notification) => {
    if (window.location.pathname.includes("plan-smart")) {
      dispatch({
        type: "SET_NOTIFICATIONS",
        payload: {
          results: {
            task_list: [
              {
                id: 1,
                label: "Updates",
                description:
                  "The IA Forecast is updated on 4/12/2023, 10:30am EST",
                read: false,
                selected: false,
              },
            ],
            info: [
              {
                id: 1,
                label: "Updates",
                description:
                  "The IA Forecast is updated on 4/12/2023, 10:30am EST",
                action: {},
                read: false,
                selected: false,
              },
            ],
            action: [],
          },
          count: 1,
          unreadCount: 1,
          dnd: false,
        },
      });
    } else if (window.location.pathname?.split("/")[2] === "price-smart") {
      dispatch({
        type: "SET_NOTIFICATIONS",
        payload: {
          results: {
            task_list: [
              {
                id: 1,
                label: "Status",
                description: "Your product information has been downloaded!",
                read: false,
                selected: false,
              },
            ],
            info: [
              {
                id: 1,
                label: "Updates",
                description: "Your product information has been downloaded!",
                read: false,
                selected: false,
              },
            ],
            action: [],
          },
          count: 1,
          unreadCount: 1,
          dnd: false,
        },
      });
    } else {
      dispatch({
        type: "SET_NOTIFICATIONS",
        payload: {
          results: {
            task_list: [
              {
                id: 1,
                label: "You're All Caught Up!",
                description:
                  "You have no new updates or messages at this time.",
                status: "success",
                read: false,
                selected: false,
              },
            ],
            info: [
              {
                id: 1,
                label: "You're All Caught Up!",
                description:
                  "You have no new updates or messages at this time.",
                status: "success",
                read: false,
                selected: false,
              },
            ],
            action: [],
          },
          count: 1,
          unreadCount: 1,
          dnd: false,
        },
      });
    }
  };

  useEffect(async () => {
    //connectWebsocket();
    const getHelpDesk = async () => {
      // let helpDeskResponse = await props.getDropdownValues(2, {
      //   attribute_name: "help_desk_url",
      // });
      let helpDeskResponse = [
        {
          name: "help_desk_url",
          attribute_value: {
            value:
              "https://impactanalytics.mojohelpdesk.com/login/create_request#/ticket-form/66734",
          },
        },
      ];
      props.setHelpDesk(helpDeskResponse?.attribute_value?.value);
    };
    getHelpDesk();
    createNotification();
  }, []);

  useEffect(() => {
    setUnreadCount(props.notificationUnreadCount);
  }, [props.notificationUnreadCount]);

  useEffect(() => {
    setDnd(props.dnd);
  }, [props.dnd]);

  useEffect(() => {
    if (window.location.pathname.includes("home")) {
      dispatch({
        type: "SET_PRODUCT_NAME",
        payload: "IA Smart Platform",
      });
    }
  }, []);
  useEffect(() => {
    setNotificationsData(props.notificationData);
  }, [props.notificationData]);

  const openSupportLinkInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const handleLogoClick = () => {
    window.location.pathname.includes("configurator")
      ? history.push(
          "/" + window.location.pathname?.split("/")[1] + "/configurator/home"
        )
      : history.push("/" + window.location.pathname?.split("/")[1] + "/home");
  };

  return (
    <header className="impact-platform-header">
      <HeaderRenderer
        title={
          headerDetails?.clientLogo
            ? ""
            : (props?.productName || "IA Smart Platform")
        }
        notificationIndicator
        showNotificationIcon
        showHelpIcon
        showMessageIcon
        showChatBotIcon={false}
        handleLogoClick={() => {
          handleLogoClick();
        }}
        handleChatBotClick={() => {}}
        handleMessageClick={() => {}}
        handleNotificationClick={() => {
          setOpen(true);
        }}
        userName={userName}
        isNotificationDnd={dnd}
      />
      <Notification open={open} setOpen={setOpen} />
      {headerDetails?.clientLogo && (
        <CustomLogo fileName={headerDetails.clientLogo} />
      )}
    </header>
  );
}

Header.propTypes = {
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  notificationUnreadCount: state.notificationReducer.notificationUnreadCount,
  dnd: state.notificationReducer.dnd,
  notificationData: state.notificationReducer.notificationData,
  helpDeskUrl: state.tenantConfigReducer.helpDesk || "",
  productName: state.layoutReducer.productName,
  headerDetails: state.layoutReducer.headerDetails,
});

export default connect(mapStateToProps, {
  logoutUser,
  setNotificationDND,
  setNotifications,
  setWebSocketClient,
  getDropdownValues,
  setActiveUserApp,
  setHelpDesk,
})(withRouter(Header));
